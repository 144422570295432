import React from 'react';
import NavRight from './NavRight';
import { NavLink } from 'react-router-dom';


const Home = () => {

    
 return (
<React.Fragment>
<div className="home">
    <header className="header" id="navbar-collapse-toggle">
    <NavRight/>
    </header>

    <section className="container-fluid main-container container-home p-0">
        <div className="color-block d-none d-lg-block"></div>
        <div className="row home-details-container align-items-center">
            <div className="col-lg-4 bg position-fixed d-none d-lg-block"></div>
            <div className="col-12 col-lg-8 offset-lg-4 home-details text-left text-sm-center text-lg-left">
                <div>
                    <img src="./img/manish.jpg" className="img-fluid main-img-mobile d-none d-sm-block d-lg-none" alt="myimg" />
                    <h6 className="text-uppercase open-sans-font mb-0 d-block d-sm-none d-lg-block">hi there !</h6>
                    <h1 className="text-uppercase poppins-font"><span>I'm</span> Manish Sharma</h1>
                    <p className="open-sans-font">I'm  Web & Mobile Application developer focused on crafting clean & user‑friendly experiences, I am passionate about building excellent application that improves the lives of those around me.</p>
                    <NavLink to="/about" className="btn btn-about">more about me</NavLink>
                </div>
            </div>
        </div>
    </section>
</div>
</React.Fragment>
 );   
}

export default Home;