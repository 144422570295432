import React from 'react'
import NavRight from './NavRight';
import './portfolio.sass'
import $ from "jquery";


class Portfolio extends React.Component{
    constructor(props){
        super(props);
    }
    
	componentDidMount(){

		$('.gallery ul li a').click(function() {
			var itemID = $(this).attr('href');
			$('.gallery ul').addClass('item_open');
			$(itemID).addClass('item_open');
			return false;
		});
		$('.close').click(function() {
			$('.port, .gallery ul').removeClass('item_open');
			return false;
		});
	   

	}
   

    componentWillMount(){
        document.body.classList.add('portfolio');
      }
        componentWillUnmount(){
            document.body.classList.remove('portfolio');
      }

    render(){
return (

    <React.Fragment>
   
        <header className="header" id="navbar-collapse-toggle">
        <NavRight/>
        </header>

       
<section class="title-section text-left text-sm-center revealator-once revealator-delay1">
    <h1>my <span>portfolio</span></h1>
    <span class="title-bg">works</span>
</section>

<section class="main-content text-center revealator-once revealator-delay1">
    <div id="grid-gallery" class="container grid-gallery">
      
    <section class="gallery">
	<div class="row">
		<ul>
			<a href="#" class="close"></a>
			<li>
				<a href="#item01">
					<img src="./img/work/1.jpg" alt="" />
				</a>
			</li>

			<li>
				<a href="#item02">
					<img src="./img/work/2.jpg" alt="" />
				</a>
			</li>

			<li>
				<a href="#item03">
					<img src="./img/work/3.jpg" alt="" />
				</a>
			</li>
			<li>
				<a class="image" href="#item04">
					<img src="./img/work/4.jpg" alt="" />
				</a>
			</li>
			<li>
				<a class="image" href="#item05">
					<img src="./img/work/5.jpg" alt="" />
				</a>
			</li>
			<li>
				<a class="image" href="#item06">
					<img src="./img/work/6.jpg" alt="" />
				</a>
			</li>
			<li>
				<a class="image" href="#item07">
					<img src="./img/work/7.jpg" alt="" />
				</a>
			</li>
			<li>
				<a class="image" href="#item08">
					<img src="./img/work/8.jpg" alt="" />
				</a>
			</li>
		</ul>
	</div>
	<div id="item01" class="port">
		<div class="row">
				<img src="./img/fullwimg/1.png" alt="" />
				<div class="description">
				<h1><img src="./img/logos/aussielandtravel.png"/></h1>
				<p>Aussielandtravel are accomplishing a successful business in the travel industry in Australia. They have collaborated with there team members working for there customer’s interests. They are working effectively to manage our customers’ demands.</p>
				<p> <b>skills :</b> 
				 <span  className="time open-sans-font text-uppercase">HTML</span>
				 <span  className="time open-sans-font text-uppercase">CSS</span>
				 <span  className="time open-sans-font text-uppercase">Javascript</span>
				 <span  className="time open-sans-font text-uppercase">PHP</span>
				 <span  className="time open-sans-font text-uppercase">MySQL</span>
				 <span  className="time open-sans-font text-uppercase">CPenal</span>
				 
				 </p>
			</div>	
		</div>

	</div> 
	
	<div id="item02" class="port">
		<div class="row">
				<img src="./img/fullwimg/2.png" alt="" />
				<div class="description">
				<h1><img src="./img/logos/technosys.png"/></h1>
				<p>Technvosys is the IT company that is established in the year 2019. We also provide fine quality work at the reasonable price in the field of website development, game development and application design.</p>
				<p> <b>skills :</b> 
				 <span  className="time open-sans-font text-uppercase">HTML</span>
				 <span  className="time open-sans-font text-uppercase">CSS</span>
				 <span  className="time open-sans-font text-uppercase">Javascript</span>
				 <span  className="time open-sans-font text-uppercase">PHP</span>
				 <span  className="time open-sans-font text-uppercase">CI</span>
				 <span  className="time open-sans-font text-uppercase">MySQL</span>
				 <span  className="time open-sans-font text-uppercase">CPenal</span>
				 
				 </p>
			</div>	
		</div>

	</div> 
	
	<div id="item03" class="port">
		<div class="row">
				<img src="./img/fullwimg/3.jpg" alt="" />
				<div class="description">
				<h1><img src="./img/logos/shome.png"/></h1>
				<p>Solar Appointments find out how much solar installation will your cost in USA.</p>
				<p><b>skills : </b>
				 <span  className="time open-sans-font text-uppercase">HTML</span>
				 <span  className="time open-sans-font text-uppercase">CSS</span>
				 <span  className="time open-sans-font text-uppercase">Javascript</span>
				 <span  className="time open-sans-font text-uppercase">PHP</span>
				 <span  className="time open-sans-font text-uppercase">Laravel</span>
				 <span  className="time open-sans-font text-uppercase">MySQL</span>
				 
				 </p>
			</div>	
		</div>

	</div> 
	
	<div id="item04" class="port">
		<div class="row">
				<img src="./img/fullwimg/4.png" alt="" />
				<div class="description">
				<h1><img src="./img/logos/nscouch.png"/></h1>
				<p>NS Couch Cleaning is a leading cleaning services in Australia that has branches in different cities of country like Sydney and Perth. We have years of experience in upholstery cleaning. With the time we have also improved methods and tools to provide better cleaning services to  customers</p>
				<p> <b>skills :</b> 
				 <span  className="time open-sans-font text-uppercase">HTML</span>
				 <span  className="time open-sans-font text-uppercase">CSS</span>
				 <span  className="time open-sans-font text-uppercase">Javascript</span>
				 <span  className="time open-sans-font text-uppercase">PHP</span>
				 <span  className="time open-sans-font text-uppercase">CI</span>
				 <span  className="time open-sans-font text-uppercase">MySQL</span>
				 </p>
		</div>
		</div>
	</div> 
	
	<div id="item05" class="port">
		<div class="row">
				<img src="./img/fullwimg/5.png" alt="" />
				<div class="description">
				<h1><img src="./img/logos/rti.png"/></h1>
				<p>Rtiguru is a RTI online webportal to file RTI applications for first / second appeal, fir status ,property details online and other RTI related to UGC , central and state.</p>
				<p> <b>skills : </b>
				 <span  className="time open-sans-font text-uppercase">HTML</span>
				 <span  className="time open-sans-font text-uppercase">CSS</span>
				 <span  className="time open-sans-font text-uppercase">Javascript</span>
				 <span  className="time open-sans-font text-uppercase">PHP</span>
				 <span  className="time open-sans-font text-uppercase">Laravel</span>
				 <span  className="time open-sans-font text-uppercase">Angular 7+</span>
				 <span  className="time open-sans-font text-uppercase">MySQL</span>
				 </p>
			</div>	
		</div>

	</div> 
	
	<div id="item06" class="port">
		<div class="row">
				<img src="./img/fullwimg/6.png" alt="" />
				<div class="description">
				<h1><img src="./img/logos/scontractor.png"/></h1>
				<p>At Solar Contractor Appointments, combine in-depth digital marketing expertise with advanced knowledge of the current solar panel industry. As a result, you get the type of PREMIUM-QUALITY, VALUABLE leads your business needs to thrive.</p>
				<p> <b>skills : </b>
				 <span  className="time open-sans-font text-uppercase">HTML</span>
				 <span  className="time open-sans-font text-uppercase">CSS</span>
				 <span  className="time open-sans-font text-uppercase">Javascript</span>
				 <span  className="time open-sans-font text-uppercase">Laravel</span>
				 <span  className="time open-sans-font text-uppercase">ReactJS</span>
				 <span  className="time open-sans-font text-uppercase">MySQL</span>
				 </p>
			</div>	
		</div>

	</div> 
	
	<div id="item07" class="port">
		<div class="row">
				<img src="./img/fullwimg/7.png" alt="" />
				<div class="description">
				<h1><img src="./img/logos/npuphos.png"/></h1>
				<p> NS upholstery cleaning is here to help you to clean all upholstery of your house that you use daily and want to clean them. We are from Australia and providing the best services for cleaning in Perth and Sydney at budgeted price.</p>
				<p> <b>skills :</b> 
				 <span  className="time open-sans-font text-uppercase">HTML</span>
				 <span  className="time open-sans-font text-uppercase">CSS</span>
				 <span  className="time open-sans-font text-uppercase">Javascript</span>
				 <span  className="time open-sans-font text-uppercase">PHP</span>
				 <span  className="time open-sans-font text-uppercase">CI</span>
				 <span  className="time open-sans-font text-uppercase">MySQL</span>
				 </p>
			</div>	
		</div>

	</div> 
	
	<div id="item08" class="port">
		<div class="row">
				<img src="./img/fullwimg/8.png" alt="" />
				<div class="description">
				<h1><img src="./img/logos/Live-assignment.png"/></h1>
				<p>Live Assignment is provide homework assistance in all major subjects for one and all problems. Our assignment expert team ensures that the help we provide is original and non-plagiarized work. </p>
				<p> <b>skills : </b>
				 <span  className="time open-sans-font text-uppercase">HTML</span>
				 <span  className="time open-sans-font text-uppercase">CSS</span>
				 <span  className="time open-sans-font text-uppercase">Javascript</span>
				 <span  className="time open-sans-font text-uppercase">Laravel</span>
				 <span  className="time open-sans-font text-uppercase">Angular</span>
				 <span  className="time open-sans-font text-uppercase">MySQL</span>
				 <span  className="time open-sans-font text-uppercase">Rest Api</span>
				 <span  className="time open-sans-font text-uppercase">Cpenal</span>
				 </p>
			</div>	
		</div>

	</div> 

</section> 
 
</div>
</section>
    </React.Fragment>

)
}
}


export default Portfolio;