
import './App.css';
import Home from './components/Home';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Blog from './components/Blog';
import Services from './components/Services'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact  path="/about" component={About} />
      <Route exact path="/portfolio" component={Portfolio} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/blog" component={Blog} />
      <Redirect to="/"/>
    </Switch>
    </BrowserRouter>
  );
}

export default App;
