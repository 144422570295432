import React from 'react'
import NavRight from './NavRight';

class Contact extends React.Component{
    constructor(props){
        super(props);
    }


    componentWillMount(){
        document.body.classList.add('contact');
      }
        componentWillUnmount(){
            document.body.classList.remove('contact');
      }

    render(){
return (

    <React.Fragment>
        <header className="header" id="navbar-collapse-toggle">
        <NavRight/>
        </header>

        <section className="title-section text-left text-sm-center revealator-once revealator-delay1">
        <h1>get in <span>touch</span></h1>
        <span className="title-bg">contact</span>
        </section>

        <section className="main-content revealator-once revealator-delay1">
        <div className="container">
            <div className="row">
      
            <div className="col-12">
                <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">For Better Connection!</h3>
                <p className="open-sans-font mb-3">Feel free to get in touch with me. I am always open to discussing new projects, creative ideas or opportunities to be part of your visions.</p>
                <p className="open-sans-font custom-span-contact position-relative">
                    <i className="fa fa-envelope-open position-absolute"></i>
                    <span className="d-block">mail me</span>imanish.indev@gmail.com
                </p>
                <p className="open-sans-font custom-span-contact position-relative">
                    <i className="fa fa-phone-square position-absolute"></i>
                    <span className="d-block">call me</span>+91 856 289 3738
                </p>
                <ul className="social list-unstyled pt-1 mb-5">
                    <li className="dribbble"><a title="GitHub" target="_blank" href="https://github.com/imanish94"><i className="fa fa-github"></i></a>
                    </li>
                    <li className="dribbble"><a title="Linkedin" target="_blank" href="https://www.linkedin.com/in/manish94"><i className="fa fa-linkedin "></i></a>
                    </li>
                    <li className="dribbble"><a title="Instagram" target="_blank" href="https://www.instagram.com/imanish.in"><i className="fa fa-instagram"></i></a>
                    </li>
                    <li className="dribbble"><a title="Facebook" target="_blank" href="https://www.facebook.com/manish.tiwari.54772728"><i className="fa fa-facebook"></i></a>
                    </li>
                    <li className="dribbble"><a title="Twitter" target="_blank" href="https://twitter.com/imanish_in"><i className="fa fa-twitter"></i></a>
                    </li>
                    {/* <li className="dribbble"><a title="youtube" target="_blank" href="https://twitter.com/imanish_in"><i className="fa fa-youtube"></i></a>
                    </li> */}
                    
                   
                </ul>
            </div>

           
        </div>
        </div>
        </section>

    </React.Fragment>

    )
}
}

export default Contact;