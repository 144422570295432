import React from 'react';
import NavRight from './NavRight';

const Blog = () => {
return (

    <>
    <div className="home">
    <header className="header" id="navbar-collapse-toggle">
        <NavRight/>
        </header>

        <section className="container-fluid main-container container-home p-0 revealator-once revealator-delay1">
        <div className="color-block d-none d-lg-block"></div>
        <div className="row home-details-container align-items-center">
            
            <div className="col-12 col-lg-8 offset-lg-4 home-details text-left text-sm-center text-lg-left">
                <div>
                    
                    
                    <h1 className="text-uppercase poppins-font">Comming Soon</h1>
                    
                    
                </div>
            </div>
        </div>
    </section>
    </div>
    </>

)
}


export default Blog;