import React from 'react';
import NavRight from './NavRight';

class About extends React.Component{
    constructor(props){
        super(props);
    }

    componentWillMount(){
        document.body.classList.add('about');
      }
    componentWillUnmount(){
        document.body.classList.remove('about');
      }

    render(){
        return (
            <React.Fragment>

            <header className="header" id="navbar-collapse-toggle">
            <NavRight/>
            </header>

            <section  className="title-section text-left text-sm-center">
                <h1>ABOUT <span>ME</span></h1>
                <span  className="title-bg">Resume</span>
            </section>

            <section  className="main-content revealator-once revealator-delay1">
                <div  className="container">
                    <div  className="row">
                    
                        <div  className="col-12 col-lg-5 col-xl-6">
                            <div  className="row">
                                <div  className="col-12">
                                    <h3  className="text-uppercase custom-title mb-0 ft-wt-600">personal infos</h3>
                                </div>
                                <div  className="col-12 d-block d-sm-none">
                                    <img src="img/manish.jpg"  className="img-fluid main-img-mobile" alt="mypic" />
                                </div>
                                <div  className="col-6">
                                    <ul  className="about-list list-unstyled open-sans-font">
                                        <li> <span  className="title">first name :</span> <span  className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">Manish</span> </li>
                                        <li> <span  className="title">last name :</span> <span  className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">Sharma</span> </li>
                                        <li> <span  className="title">Age :</span> <span  className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">27 Years</span> </li>
                                        <li> <span  className="title">Nationality :</span> <span  className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">Indian</span> </li>
                                        <li> <span  className="title">Freelance :</span> <span  className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">Available</span> </li>
                                    </ul>
                                </div>
                                <div  className="col-6" style={{paddingRight : '0px'}}>
                                    <ul  className="about-list list-unstyled open-sans-font" >
                                        <li> <span  className="title">Address :</span> <span  className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">Jaipur, RJ</span> </li>
                                        <li> <span  className="title">phone :</span> <span  className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">+91 856 289 3738</span> </li>
                                        <li> <span  className="title">Email :</span> <span  className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">imanish.indev@gmail.com</span> </li>
                                        <li className="skype"> <span  className="title">Skype :</span><a href="https://join.skype.com/invite/la3WK494AeFs"> <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">manish.sharma</span></a> </li>
                                        <li> <span  className="title">langages :</span> <span  className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">English, Hindi</span> </li>
                                    </ul>
                                </div>
                                <div  className="col-12 mt-3">
                                    <a href="https://drive.google.com/file/d/1XtuB-RIG1vGM6biomsDI6RZNQ7hQFJtf/view?usp=sharing" target="_blank" className="btn btn-download">Download CV</a>
                                </div>
                            </div>
                        </div>
                
                        <div  className="col-12 col-lg-7 col-xl-6 mt-5 mt-lg-0">
                            <div  className="row">
                                <div  className="col-6">
                                    <div  className="box-stats with-margin">
                                        <h3  className="poppins-font position-relative">3</h3>
                                        <p  className="open-sans-font m-0 position-relative text-uppercase">years of <span  className="d-block">experience</span></p>
                                    </div>
                                </div>
                                <div  className="col-6">
                                    <div  className="box-stats with-margin">
                                        <h3  className="poppins-font position-relative">50</h3>
                                        <p  className="open-sans-font m-0 position-relative text-uppercase">completed <span  className="d-block">projects</span></p>
                                    </div>
                                </div>
                                <div  className="col-6">
                                    <div  className="box-stats">
                                        <h3  className="poppins-font position-relative">50</h3>
                                        <p  className="open-sans-font m-0 position-relative text-uppercase">Happy<span  className="d-block">customers</span></p>
                                    </div>
                                </div>
                                <div  className="col-6">
                                    <div  className="box-stats">
                                        <h3  className="poppins-font position-relative">15</h3>
                                        <p  className="open-sans-font m-0 position-relative text-uppercase">use <span  className="d-block">Technology</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                    <hr  className="separator" />
                
                    <div  className="row">
                        <div  className="col-12">
                            <h3  className="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600">My Skills</h3>
                        </div>
                        <div  className="col-6 col-md-3 mb-3 mb-sm-5">
                            <div  className="c100 p85">
                                <span>85%</span>
                                <div  className="slice">
                                    <div  className="bar"></div>
                                    <div  className="fill"></div>
                                </div>
                            </div>
                            <h6  className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">UI Development</h6>
                        </div>
                        <div  className="col-6 col-md-3 mb-3 mb-sm-5">
                            <div  className="c100 p80">
                                <span>80%</span>
                                <div  className="slice">
                                    <div  className="bar"></div>
                                    <div  className="fill"></div>
                                </div>
                            </div>
                            <h6  className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">Javascript</h6>
                        </div>
                        <div  className="col-6 col-md-3 mb-3 mb-sm-5">
                            <div  className="c100 p80">
                                <span>80%</span>
                                <div  className="slice">
                                    <div  className="bar"></div>
                                    <div  className="fill"></div>
                                </div>
                            </div>
                            <h6  className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">React JS</h6>
                        </div>               
                        <div  className="col-6 col-md-3 mb-3 mb-sm-5">
                            <div  className="c100 p78">
                                <span>78%</span>
                                <div  className="slice">
                                    <div  className="bar"></div>
                                    <div  className="fill"></div>
                                </div>
                            </div>
                            <h6  className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">Wordpress</h6>
                        </div>               
                        <div  className="col-6 col-md-3 mb-3 mb-sm-5">
                            <div  className="c100 p75">
                                <span>75%</span>
                                <div  className="slice">
                                    <div  className="bar"></div>
                                    <div  className="fill"></div>
                                </div>
                            </div>
                            <h6  className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">Node & Express</h6>
                        </div>    
                        <div  className="col-6 col-md-3 mb-3 mb-sm-5">
                            <div  className="c100 p75">
                                <span>75%</span>
                                <div  className="slice">
                                    <div  className="bar"></div>
                                    <div  className="fill"></div>
                                </div>
                            </div>
                            <h6  className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">Angular 7+</h6>
                        </div>                    
                        <div  className="col-6 col-md-3 mb-3 mb-sm-5">
                            <div  className="c100 p70">
                                <span>70%</span>
                                <div  className="slice">
                                    <div  className="bar"></div>
                                    <div  className="fill"></div>
                                </div>
                            </div>
                            <h6  className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">Laravel</h6>
                        </div>
                        <div  className="col-6 col-md-3 mb-3 mb-sm-5">
                            <div  className="c100 p60">
                                <span>60%</span>
                                <div  className="slice">
                                    <div  className="bar"></div>
                                    <div  className="fill"></div>
                                </div>
                            </div>
                            <h6  className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">Git & AWS</h6>
                        </div>
                        </div>
                    <hr  className="separator mt-1" />
                    <div  className="row">
                        <div  className="col-12">
                            <h3  className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">Experience <span>&</span> Education</h3>
                        </div>
                        <div  className="col-lg-6 m-15px-tb">
                            <div  className="resume-box">
                                <ul>
                                    <li>
                                        <div  className="icon">
                                            <i  className="fa fa-briefcase"></i>
                                        </div>
                                        <span  className="time open-sans-font text-uppercase">2021 - Present</span>
                                        <h5  className="poppins-font text-uppercase">React Developer<span  className="place open-sans-font"><b className="taggbox">Taggbox</b></span></h5>
                                        <p  className="open-sans-font">Createing UGC Display Eventes Theme in React Js with Redux, Node Api & SASS.</p>
                                    </li>
                                    <li>
                                        <div  className="icon">
                                            <i  className="fa fa-briefcase"></i>
                                        </div>
                                        <span  className="time open-sans-font text-uppercase">2019 - 2021</span>
                                        <h5  className="poppins-font text-uppercase">Software Developer<span  className="place open-sans-font"><b className="survin">Survin</b> IT Solution</span></h5>
                                        <p  className="open-sans-font">Utilized such technologies as Laravel, React, Angular, HTML5, JavaScript to develop new applications.</p>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div  className="col-lg-6 m-15px-tb">
                            <div  className="resume-box">
                                <ul>

                                <li>
                                        <div  className="icon">
                                            <i  className="fa fa-briefcase"></i>
                                        </div>
                                        <span  className="time open-sans-font text-uppercase">2018 - 2019</span>
                                        <h5  className="poppins-font text-uppercase">Software Developer<span  className="place open-sans-font"><b className="saachi">Saachi</b> Technology</span></h5>
                                        <p  className="open-sans-font">Built shared central authentication system in React & Laravel language for intranet applications.</p>
                                    </li>
                                   
                                    <li>
                                        <div  className="icon">
                                            <i  className="fa fa-graduation-cap"></i>
                                        </div>
                                        <span  className="time open-sans-font text-uppercase">2015</span>
                                        <h5  className="poppins-font text-uppercase">BCA <span  className="place open-sans-font">University Of Rajasthan</span></h5>
                                        <p  className="open-sans-font"></p>
                                    </li>
                                
                                </ul>
                            </div>
                        </div>
                    </div>
                
                </div>
            </section>
            </React.Fragment>
        )
    }   

}

export default About;