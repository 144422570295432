import React from 'react';
import { Link } from 'react-router-dom';
const NavRight = () => {
// console.log("window",window.location.pathname)
    return(
        <React.Fragment>
        <ul className="icon-menu d-none d-lg-block">
        <li className={window.location.pathname==="/"?'icon-box active':'icon-box'}>
            <i className="fa fa-home"></i>
            <Link to="/">
                <h2>Home</h2>
            </Link>
        </li>
        <li className={window.location.pathname==="/about"?'icon-box active':'icon-box'}>
            <i className="fa fa-user"></i>
            <Link to="/about">
                <h2>About</h2>
            </Link>
        </li>
        <li className={window.location.pathname==="/portfolio"?'icon-box active':'icon-box'}>
            <i className="fa fa-briefcase"></i>
            <Link to="/portfolio">
                <h2>Portfolio</h2>
            </Link>
        </li>
        <li className={window.location.pathname==="/services"?'icon-box active':'icon-box'}>
            <i className="fa fa-cogs"></i>
            <Link to="/services">
                <h2>Services</h2>
            </Link>
        </li>
        <li className={window.location.pathname==="/contact"?'icon-box active':'icon-box'}>
            <i className="fa fa-envelope-open"></i>
            <Link to="/contact">
                <h2>Contact</h2>
            </Link>
        </li>
        <li className={window.location.pathname==="/blog"?'icon-box active':'icon-box'}>
            <i className="fa fa-comments"></i>
            <Link to="/blog">
                <h2>Blog</h2>
            </Link>
        </li>
    </ul>

    <nav role="navigation" className="d-block d-lg-none">
        <div id="menuToggle">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul className="list-unstyled" id="menu">
                <li className={window.location.pathname==="/"?'active':''}>
                    <Link to="/">
                    <i className="fa fa-home"></i><span>Home</span>
                    </Link>
                </li>
                <li className={window.location.pathname==="/about"?'active':''}>
                    <Link to="/about">
                        <i className="fa fa-user"></i><span>About</span>
                    </Link>
                </li>
                <li className={window.location.pathname==="/portfolio"?'active':''}><Link to="/portfolio"><i className="fa fa-folder-open"></i><span>Portfolio</span></Link></li>
                <li className={window.location.pathname==="/services"?'active':''}><Link to="/services"><i className="fa fa-cogs"></i><span>Services</span></Link></li>
                <li className={window.location.pathname==="/contact"?'active':''}><Link to="/contact"><i className="fa fa-envelope-open"></i><span>Contact</span></Link></li>
                <li className={window.location.pathname==="/blog"?'active':''}><Link to="/blog"><i className="fa fa-comments"></i><span>Blog</span></Link></li>
            </ul>
        </div>
    </nav>
    

        </React.Fragment>
    )

}

export default NavRight;